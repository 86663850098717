import React, { Component } from "react";
import Col from 'react-bootstrap/Col';

import GroupCard from './groupCard'

import { StaticQuery, graphql } from "gatsby";

class Groups extends Component {

  constructor(props) {
    super(props);
  }

  getMinMD(data) {
    const size = data.allGroupsJson.edges.filter(edge => this.props.groupsId.includes(edge.node.jsonId)).length;
    let md = 12;
    switch(size) {
      case 1:
        md = 6;
        break;
      case 2:
      case 3:
      case 4:
        md = 3;
        break;
    }
    return md;
  }

  render() {
    return (
      <StaticQuery
        query={
          graphql`
            query GroupsJson {
              allGroupsJson {
                edges {
                  node {
                    jsonId
                    name
                    description
                    fields {
                      src {
                        childImageSharp {
                          gatsbyImageData(layout: CONSTRAINED, width: 300, height: 300)
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
        render={data => (
          <>
            <h3 className="title-under-section mx-3">Groups</h3>
            {data.allGroupsJson.edges.filter(edge => this.props.groupsId.includes(edge.node.jsonId)).map(edge => 
              <Col key={edge.node.jsonId} md={this.getMinMD(data)} xs="6" className="text-center mx-auto">
                <GroupCard
                  key={edge.node.jsonId}
                  photo={edge.node.fields.src}
                  name={edge.node.name}
                  description={edge.node.description}
                />
              </Col>
            )}
          </>
        )}
      />
    );
  }
}
 
export default Groups;