import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import SocialFollow from "./components/socialFollow";
 
class Footer extends Component {
  render() {
    return (
      <Container className="footer">
        <Row className="justify-content-center align-items-center">
          <Col xs="auto">
              <SocialFollow/>
          </Col>
        </Row>
      </Container>
    );
  }
}
 
export default Footer;