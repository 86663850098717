import React, { Component } from "react";
import Medias from "./../sections/medias";
import Events from "./../sections/events";
import About from "./../sections/about";
import News from "../sections/news";
import Header from "./../sections/header";
import Footer from "./../sections/footer";
import { Helmet } from "react-helmet";
import CountdownEvent from "../sections/components/countdown/countdownEvent";

import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/background.css';
import './../css/main.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../css/socialFollow.css";
import 'lightbox-react/style.css';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Particles from "react-tsparticles";
import particlesOptions from "../json/particles.json";


class IndexPage extends Component {

    render() {
        return (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>The Raven Paul Collection</title>
              <link rel="canonical" href="https://raven-paul.com/" />
            </Helmet>
            <Particles options={particlesOptions} />
            <Header/>
            <Container>
                <Row>
                  <CountdownEvent/>
                </Row>
                <News/>
                <Events/>
                <Medias/>
                <About/>
            </Container>
            <Footer/>
          </>
        );
    }
}
 
export default IndexPage;