import Avatar from './../images/avatar.jpg'
import Band from './../images/band.png'
import LogoMenu from './../images/logo_menu_1200_coupe.png'

const images = {
    Avatar,
    Band,
    LogoMenu
};

function getImageByKey(key) {
    return images[key];
};

export default getImageByKey;