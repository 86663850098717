import React from "react";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

export function zeroPad(number) {
    return (number < 10 ? "0" + number : number);
};

export function convertHoursToUS(hours, minutes) {
    let usHours = hours;
    const isPm = hours >= 12;
    if (hours > 12) {
        usHours = hours - 12;
    }
    return zeroPad(usHours) + ":" + zeroPad(minutes) + " " + (isPm ? "p.m" : "a.m");
};

export function ouputDate(dateStr) {
    const date = new Date(dateStr);
    return date.getFullYear() + "-" +
        zeroPad(date.getMonth()) + "-" +
        zeroPad(date.getDay()) + " " +
        convertHoursToUS(date.getHours(), date.getMinutes());
};

export function CustomToggle({ children, eventKey, refState }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => { });

    return (
        <button
            ref={refState}
            type="button"
            style={{ backgroundColor: 'pink' }}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
};

export function splitArray(input, spacing)
{
    var output = [];

    for (var i = 0; i < input.length; i += spacing)
    {
        output[output.length] = input.slice(i, i + spacing);
    }

    return output;
};