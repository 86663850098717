import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";

class Menu extends Component {
  render() {
    return (
      <Navbar className="navbar-menu">
        <Container>
          <Nav className="mx-auto nav-menu">
            <Nav.Link href="#news" className="menu">News</Nav.Link>
            <Nav.Link href="#events" className="menu">Events</Nav.Link>
          </Nav>
          <Nav className="mx-auto">
            <Nav.Link href="#medias" className="menu">Medias</Nav.Link>
            <Nav.Link href="#about-us" className="menu">About us</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    )
  }
}
 
export default Menu;