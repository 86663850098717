import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { CustomToggle } from '../../../utils/utils';
import Groups from './../../components/groups';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  render() {
    return (
      <>
        <h3 className="title-under-section mx-3">{this.props.name}</h3>
        <Row>
          <Col xs="2">
            <Row className="justify-content-center">
              <Col xs="auto">
                <GatsbyImage image={getImage(this.props.photo)} className="profile-img" />
              </Col>
              <Col xs="auto">
                <Button variant="light" className="more-btn" onClick={() => this.myRef.current.click()}>More</Button>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="auto">
                <div className="profile-description">
                  <p className="inline-p">{this.props.description}</p>
                </div>
              </Col>
              <Col xs="auto">
                <Accordion defaultActiveKey="1">
                  <Card className="accordion-header-border">
                    <Card.Header className="accordion-header-hide">
                      <CustomToggle refState={this.myRef} eventKey="0"></CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <Row className="mt-5">
                          <Groups groupsId={this.props.groupsId} />
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>

        </Row>
      </>
    );
  }
}

export default Profile;