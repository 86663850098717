import React, { Component } from "react";
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import getImageByKey from "../../../utils/getImageByKey";
import { ouputDate } from '../../../utils/utils';

const default_avatar = "Band";

class EventCard extends Component {
  render() {
    return (
      <Card className="event-card">
        <Card.Img variant="top"
          src={getImageByKey(this.props.photoId ? this.props.photoId : default_avatar)} />
        <Card.Body>
          <Card.Title>{this.props.name}</Card.Title>
          <Card.Text>
            {this.props.description}
          </Card.Text>
        </Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroupItem>Date: {ouputDate(this.props.date)}</ListGroupItem>
          <ListGroupItem>Address: {this.props.address}</ListGroupItem>
        </ListGroup>
      </Card>
    );
  }
}
 
export default EventCard;