import React, { Component } from "react";
import Col from 'react-bootstrap/Col';

import GroupCard from './groupCard'

import { StaticQuery, graphql } from "gatsby";

class Collections extends Component {

  render() {
    return (
      <StaticQuery
        query={
          graphql`
            query CollectionsJson {
              allGroupsJson(filter: {catagorie: {eq: "other"}}) {
                edges {
                  node {
                    jsonId
                    name
                    description
                    fields {
                      src {
                        childImageSharp {
                          gatsbyImageData(layout: CONSTRAINED, width: 300)
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
        render={data => (
          <>
            <h3 className="title-section mx-3">Collections</h3>
            {data.allGroupsJson.edges.map(edge => {
              return (
                <Col key={edge.node.jsonId} md="3" xs="6" className="text-center mx-auto">
                  <GroupCard
                    photo={edge.node.fields.src}
                    name={edge.node.name}
                    description={edge.node.description}
                  />
                </Col>
              )
            })}
          </>
        )}
      />
    );
  }
}
 
export default Collections;