import React, { Component } from "react";
import Image from "react-bootstrap/Image";

import getImageByKey from '../utils/getImageByKey';

const LOGO_MENU = "LogoMenu";

class Logo extends Component {
  render() {
    return (
      <Image src={getImageByKey(LOGO_MENU)} fluid />
    );
  }
}
 
export default Logo;