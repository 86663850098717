import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Collections from './components/collections'
import Profile from "./components/about/profile";

import { StaticQuery, graphql } from "gatsby";
  
class About extends Component {
  
  render() {
    return (
      <StaticQuery
        query={
          graphql`
            query RavenPaulMembersJson {
              allMembersJson(filter: {groups_id: {in: "raven_paul"}}) {
                edges {
                  node {
                    jsonId
                    name
                    description
                    groups_id
                    fields {
                      src {
                        childImageSharp {
                          gatsbyImageData(layout: CONSTRAINED, width: 300, height: 300)
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
        render={data => (
          <>
            <Row>
              <a id="about-us" className="anchor"/>
              <h2 className="title-section">About us</h2>
              <p>Cras facilisis urna ornare ex volutpat, et
              convallis erat elementum. Ut aliquam, ipsum vitae
              gravida suscipit, metus dui bibendum est, eget rhoncus nibh
              metus nec massa. Maecenas hendrerit laoreet augue
              nec molestie. Cum sociis natoque penatibus et magnis
              dis parturient montes, nascetur ridiculus mus.</p>
              <Container fluid>
                {data.allMembersJson.edges.map(edge => {
                  return (
                    <Row key={edge.node.jsonId} className="mt-5">
                      <Col>
                        <Profile
                          name={edge.node.name}
                          description={edge.node.description}
                          photo={edge.node.fields.src}
                          groupsId={edge.node.groups_id}
                        />
                      </Col>
                    </Row>
                  )
                })}
                <Row className="mt-5">
                  <Collections/>
                </Row>
              </Container>
            </Row>
          </>
        )}
      />
    );
  }
}
 
export default About;