import React, { Component } from "react";
import Card from 'react-bootstrap/Card'

class CountdownCard extends Component {
  render() {
    return (
      <Card className="countdown-time-card">
        <Card.Body>
          <Card.Text className="countdown-large-font">
            {this.props.value}
          </Card.Text>
        </Card.Body>
        <div className="text-muted countdown-name">{this.props.name}</div>
      </Card>
    );
  }
}
 
export default CountdownCard;