import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import EventCard from './eventCard';
import CodeSlider from '../slider';

class EventsCarousel extends Component {

  render() {
    return (
      <StaticQuery
        query={
          graphql`
            query CarouselEventsJson {
              allEventsJson {
                edges {
                  node {
                    jsonId
                    name
                    date
                    address
                    photoId
                    description
                  }
                }
              }
            }
          `}
        render={data => (
          <>
            <CodeSlider codes={data.allEventsJson.edges.map(edge => 
                <EventCard key={edge.node.jsonId}
                  photoId={edge.node.photoId}
                  description={edge.node.description}
                  address={edge.node.address}
                  date={edge.node.date}
                  name={edge.node.name}
                />
              )}
            />
          </>
        )}
      />
    );
  }
}

export default EventsCarousel;