import React, { Component } from "react";
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Countdown from 'react-countdown';
import CountdownCard from "./countdownCard";
import { StaticQuery, graphql } from "gatsby";
import { ouputDate } from '../../../utils/utils';
 
class CountdownEvent extends Component {
  renderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <Row className="justify-content-center">
        <Col xs="2" className="countdown-col"><CountdownCard name="days" value={days} /></Col>
        <Col xs="2" className="countdown-col"><CountdownCard name="hours" value={hours} /></Col>
        <Col xs="2" className="countdown-col"><CountdownCard name="minutes" value={minutes} /></Col>
        <Col xs="2" className="countdown-col"><CountdownCard name="seconds" value={seconds} /></Col>
      </Row>
    );
  };

  titleEvent(eventDateStr) {
    return (Date.now() < new Date(eventDateStr) ? "Upcomming": "Last") + " event";
  }

  getFirstNextDate(edges) {
    const dateNow = Date.now();
    let filterDate = edges.filter(edge => dateNow < new Date(edge.node.date));
    if (filterDate.length > 0) {
      return (
        <Card key={filterDate[0].node.jsonId} className="countdown-time-card">
          <Card.Body>
            <Card.Title className="countdown-center-text countdown-large-font">{this.titleEvent(filterDate[0].node.date)}</Card.Title>
            <Card.Subtitle className="mb-2 countdown-center-text">{ouputDate(filterDate[0].node.date) + " - " + filterDate[0].node.name}</Card.Subtitle>
            <Countdown
              date={filterDate[0].node.date}
              renderer={this.renderer}
            />
            <Card.Footer className="text-muted countdown-center-text countdown-footer">
              {filterDate[0].node.address}
              <Button variant="light" className="countdown-btn">More</Button>
            </Card.Footer>
          </Card.Body>
        </Card>
      );
    } else {
      return (<></>);
    }
  }

  render() {
    return (
      <StaticQuery
        query={
          graphql`
            query CountdownJson {
              allEventsJson {
                edges {
                  node {
                    jsonId
                    name
                    date
                    address
                  }
                }
              }
            }
          `}
        render={data => (
          <>
            {this.getFirstNextDate(data.allEventsJson.edges)}
          </>
        )}
      />
    );
  }
}
 
export default CountdownEvent;