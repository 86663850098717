import React, { Component } from "react";

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Lightbox from 'lightbox-react';

import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";

class DynamicGallery extends Component {

  constructor(props) {
    super(props);
    this.state = {
      photos: []
    };
    this.openLightbox.bind(this);
  }

  loadPhotosGroup() {
    this.state.photos = this.props.photos.filter(
      photo => photo.keywords.includes(this.props.keyword));
  };
  
  openLightbox = (event) => {
    this.setState({ currentImage: event.target.id, viewerIsOpen: true });
  };

  closeLightbox = () => {
    this.setState({ viewerIsOpen: false });
  };

  movePrevLightbox = () => {
    this.setState({
      currentImage: this.getPrevSrcIndex(),
    });
  };

  moveNextLightbox = () => {
    this.setState({
      currentImage: this.getNextSrcIndex(),
    });
  };

  getMainSrc = () => {
    const { currentImage } = this.state;
    return this.getContent(currentImage);
  }

  getNextSrc = () => {
    return this.getContent(this.getNextSrcIndex());
  }

  getPrevSrc = () => {
    return this.getContent(this.getPrevSrcIndex());
  }

  getNextSrcIndex = () => {
    const { currentImage, photos } = this.state;
    return (currentImage + 1) % photos.length;
  }

  getPrevSrcIndex = () => {
    const { currentImage, photos } = this.state;
    return (currentImage + photos.length - 1) % photos.length;
  }

  getContent(index) {
    const { photos } = this.state;
    if (photos[index].type === "image") {
      return getSrc(photos[index].src);
    } else if (photos[index].type === "video") {
      // Create custom video iframe
      return;
    }
  }

  render() {
    this.loadPhotosGroup();
    const { viewerIsOpen, photos } = this.state;

    return (
      <>
        {viewerIsOpen && (
          <Lightbox
            mainSrc={this.getMainSrc()}
            nextSrc={this.getNextSrc()}
            prevSrc={this.getPrevSrc()}
            onCloseRequest={this.closeLightbox}
            onMovePrevRequest={this.movePrevLightbox}
            onMoveNextRequest={this.moveNextLightbox}
          />
        )}

        <ResponsiveMasonry columnsCountBreakPoints={{700: 4,  800: 5, 1000: 6}}>
            <Masonry gutter="10px">
              {
                Object.entries(photos).map(([keyMap,photo]) =>
                  <GatsbyImage 
                    key={keyMap} 
                    id={keyMap} 
                    className="slick-slide-image" 
                    alt={photo.description} 
                    image={getImage(photo.src)} 
                    onClick={this.openLightbox} />
              )}
            </Masonry>
        </ResponsiveMasonry>
      </>
    );
  }
}

export default DynamicGallery;