import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import EventsCarousel from "./components/events/eventsCarousel";

class Events extends Component {

  render() {
    return (
      <Row>
        <a id="events" className="anchor"/>
        <h2 className="title-section">Events</h2>
        <EventsCarousel/>
      </Row>
    );
  }
}
 
export default Events;