import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from './logo'
import Menu from "./menu"
 
const PCT_HEIGHT_MENU_FIX = 40;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barVisibility: false,
      scrollPosition: 0,
      menuPosition: 0
    }
  }

  handleScroll() {
    const position = window.pageYOffset;
    if (position >= this.state.menuPosition) {
      this.setState({
        barVisibility: true,
        scrollPosition: position
      });
    } else {
      this.setState({
        barVisibility: false,
        scrollPosition: position
      });
    }
  }

  componentDidMount() {
    let objNav = document.querySelector(".header-menu");
    let memoPositionNav = objNav.offsetHeight;
    this.setState({
      menuPosition: memoPositionNav / 100 * PCT_HEIGHT_MENU_FIX
    });
    window.addEventListener("scroll", this.handleScroll.bind(this), { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  render() {
    return (
      <Row className={this.state.barVisibility ? "sticky-header header-menu" : "header-menu"} >
        <Col className="text-center">
          <Logo/> 
          <Menu/>
        </Col>
      </Row>
    );
  }
}
 
export default Header;