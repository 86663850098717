import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Col from "react-bootstrap/Col";

import { StaticQuery, graphql } from "gatsby";
import DynamicGallery from "./components/dynamicGallery";

class Medias extends Component {

  constructor(props) {
    super(props);
    this.state = {
      keyword: ""
    };
    this.loadKeyword = this.loadKeyword.bind(this);
  }

  loadKeyword(keyword) {
    this.setState({ keyword: keyword })
  }

  onSelectPane = (eventKey, event) => {
    if (eventKey === "all") {
      this.loadKeyword("All");
    }
  }

  componentDidMount() {
    this.loadKeyword("All");
  }

  render() {
    return (
      <StaticQuery
        query={
          graphql`
            query PhotosJson {
              allPhotosJson {
                edges {
                  node {
                    id
                    title
                    caption
                    type
                    keywords
                    fields {
                      src {
                        childImageSharp {
                          gatsbyImageData(layout: CONSTRAINED, width: 1920)
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
        render={data => (
          <>
            <Row>
              <a id="medias" className="anchor"/>
              <h2 className="title-section">Medias</h2>
              <Col>
                <Row>
                  <Col className="media-menu-center">
                    <Tabs defaultActiveKey="all" id="uncontrolled-tab-example" className="mb-3" onSelect={this.onSelectPane}>
                      <Tab eventKey="all" title="All"/>
                      <Tab eventKey="groups" title="Groups">
                        <Row>
                          <Col><div className="media-menu-tab" onClick={() => this.loadKeyword("Raven_Paul")}>Raven Paul</div></Col>
                          <Col><div className="media-menu-tab" onClick={() => this.loadKeyword("Poster")}>Poster</div></Col>
                        </Row>
                      </Tab>
                        <Tab eventKey="others" title="Others">
                        <Row>
                          <Col><div className="media-menu-tab" onClick={() => this.loadKeyword("Theater")}>Theater</div></Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
                <Row>
                  <DynamicGallery 
                    keyword={this.state.keyword}
                    photos={data.allPhotosJson.edges.map(edge => {
                      return { 
                        itemId: edge.node.id,
                        src: edge.node.fields.src,
                        type: edge.node.type,
                        title: edge.node.title,
                        description: edge.node.caption,
                        keywords: edge.node.keywords
                      }
                  })} />
                </Row>
              </Col>
            </Row>
          </>
        )}
      />
    );
  }
}

export default Medias;