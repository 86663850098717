import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { Component } from "react";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';


class GroupCard extends Component {
  render() {
    return (
      <Card className="accordion-header-border">
        <GatsbyImage image={getImage(this.props.photo)} className="group-img" />
        <Card.Body>
          <Card.Title>{this.props.name}</Card.Title>
          <Card.Text>
            {this.props.description}
          </Card.Text>
          <ListGroup className="list-group-flush">
            <ListGroupItem className="card-transparent">Creation date</ListGroupItem>
            <ListGroupItem className="card-transparent">Members</ListGroupItem>
            <ListGroupItem className="card-transparent">Sigles contact</ListGroupItem>
          </ListGroup>
        </Card.Body>
      </Card>
    );
  }
}
 
export default GroupCard;