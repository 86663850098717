import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { Col, Row } from "react-bootstrap";

class SocialFollow extends Component {
    render() {
        return (
            <div className="social-container">
                <Row>
                    <h3 className="follow-title">Follow us</h3>
                </Row>
                <Row>
                    <Col xs="6" className="social-col">
                        <a href="https://www.facebook.com/theravenpaul/"
                            className="facebook social" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebook} size="2x" />
                        </a>
                    </Col>
                    <Col xs="6" className="social-col">
                        <a href="https://www.instagram.com/remivpaul"
                            className="instagram social" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} size="2x" />
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="copyright">Copyright © 2021 - {new Date().getFullYear()}</p>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default SocialFollow;
